import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, ErrorHandler, HostListener, OnInit, ViewChild } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable, Subscription } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { HelpData } from "src/app/helpers/helpData";
import { AuthService } from "src/app/providers/auth.service";
import { environment } from "../environments/environment";
import { ChangeLangMode } from "./components/change-lang/change-lang.component";
import { OrgServiceSelectionComponent } from "./components/org-service-selection-dialog/org-service-selection-dialog.component";
import { UserInfosComponent } from "./components/user-infos/user-infos.component";
import { UserHelper } from "./helpers/UserHelper";
import { FileLogger } from "./helpers/fileLogger";
import { Account } from "./models/account.interface";
import { AppError } from "./models/app-error.interface";
import { IMaintenance, MaintenanceStatus } from "./models/maintenance.interface";
import { Reference } from "./models/reference.interface";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MaintenanceApiService } from "./providers/api/maintenance-api.service";
import { GlobalErrorHandler } from "./providers/global-error-handler";
import { SessionService } from "./providers/session.service";
import { UserService } from "./providers/user.service";
import { UserStatisticsService } from "./providers/userStatistics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("drawer") drawer: MatSidenav;
  @ViewChild(UserInfosComponent) userInfos: UserInfosComponent;

  public langMode = ChangeLangMode.DROPDOWNSHORT;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );
  public ENV = environment;
  public globalError: AppError;
  public loading: boolean;
  public isExpanded = true;
  public showSubmenu = false;
  public isShowing = false;
  public pageTitle = "";
  public ownOrgString = "";
  public ownServiceString = "";
  public user: Account;
  public isAuthorizedSelectOrg = false;
  public maintenance: IMaintenance;
  public MaintenanceStatus = MaintenanceStatus;
  public smallTopMenu = true;
  public expandTopMenu = false;
  private loadingLoginPage$: Subscription = null;

  public currentOrganization: Reference;
  public currentService: Reference;

  private menuAccess = {};
  public isAdmin: boolean;
  public isMobile: boolean;

  constructor(
    private globalErrorHandler: ErrorHandler,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public helpData: HelpData,
    private statService: UserStatisticsService,
    private dateAdapter: DateAdapter<unknown>,
    public matIconRegistry: MatIconRegistry,
    private maintenanceService: MaintenanceApiService,
    private dialog: MatDialog
  ) {
    matIconRegistry.registerFontClassAlias("solid", "fas");
    this.breakpointObserver.observe([Breakpoints.Handset, "(max-width: 1024px)"]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
  ngOnInit(): void {
    // Listen global error
    (this.globalErrorHandler as GlobalErrorHandler).catchedError.subscribe((error: AppError) => {
      // also console error to get source only in not production env
      if (!environment.production) {
        FileLogger.error("AppComponent", "globalErrorHandler in ngOnInit", error, "none");
      }
      this.globalError = error;
      this.snackBar.open(error.message, "ok", {
        duration: 10000,
      });
    });

    // https://angular.io/api/router/RouterEvent
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
      // https://stackoverflow.com/questions/45450739/angular4-x-navigationend-url-vs-navigationend-urlafterredirects
      this.setupPageTitle(ev.urlAfterRedirects ? ev.urlAfterRedirects : ev.url);
      this.maintenanceService
        .getFutureMaintenances()
        .then((m) => {
          if (m && m.length > 1) {
            m.sort((a: IMaintenance, b: IMaintenance) => {
              if (moment(a.plannedStart).isBefore(moment(b.plannedStart))) {
                return -1;
              } else {
                return 1;
              }
            });
          }
          if (m && m.length > 0) {
            this.maintenance = m[0];
          } else {
            this.maintenance = null;
          }
        })
        .catch((err) => {
          FileLogger.error("AppComponent", "Error while downloading future maintenances", err, "none");
          this.maintenance = null;
        });

      this.sessionService.refreshCurrentOrganization.subscribe(() => {
        this.currentOrganization = this.sessionService.organization;
      });

      this.sessionService.refreshCurrentService.subscribe(() => {
        this.currentService = this.sessionService.currentService;
      });
    });

    this.dateAdapter.setLocale(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(() => {
      this.dateAdapter.setLocale(this.translateService.currentLang);
    });

    this.sessionService.getObservableAccount().subscribe((account) => {
      this.isAdmin = this.sessionService.isAdmin();
      this.user = account;
      if (!account) {
        return;
      }
      this.ownOrgString = UserHelper.getOrganisation(this.user);
      this.ownServiceString = UserHelper.getHealthcareService(this.user);
      this.setupIsAuthorizedSelectOrg();
      if (this.user && !this.isAdmin) {
        this.statService.updateStatSummary("dashboardVisits");
      }
    });
    const width = window.innerWidth;
    if (width && width > 1320) {
      this.smallTopMenu = false;
    }
  }

  @HostListener("window:resize", ["$event"])
  public onResize(event) {
    const width = event.target.innerWidth;
    if (width && width > 1320) {
      this.smallTopMenu = false;
    } else {
      this.smallTopMenu = true;
    }
  }

  /**
   * Method to link child components with appComponent
   * @param componentReference child Component
   */
  public onActivate(componentReference: unknown): void {
    if (componentReference instanceof LoginPageComponent) {
      // management of the appComponent loader display according to the Login page
      if (this.loadingLoginPage$) {
        this.loadingLoginPage$.unsubscribe();
      }
      this.loadingLoginPage$ = componentReference.loadingPage.subscribe((loading) => {
        this.loading = loading;
      });
    }
  }

  public logout(): void {
    this.drawer.close();
    setTimeout(() => {
      const logoutAns = this.sessionService.idToken !== null;
      const lougoutEhealth = this.authService.logout();
      if (!logoutAns && !lougoutEhealth) {
        this.router.navigate(["/"]);
      }
    }, 0); // we need this timeOut to be sure that every javascript changes are done before disconnecting
  }

  public get isFullscreen(): boolean {
    return this.router.url === "/login";
  }

  public get isLoggedIn(): boolean {
    return this.sessionService.isLoggedIn;
  }

  private setupPageTitle(url: string) {
    let name = url.split(";")[0].replace("/", "");
    name = name?.split("?")[0];

    // remove the child path from the name
    // e.g. page.careplanEditor/tab1.title --> page.careplanEditor.title
    name = name?.split("/")[0];

    if ((!url.includes("%3B") && !url.includes("%3D") && name) || url.includes("patient;id")) {
      this.pageTitle = "page." + name + ".title";
    } else if (url.includes("%3B") && url.includes("%3D")) {
      this.router.navigateByUrl(url.replace("%3B", ";").replace("%3D", "="));
    } else if (url.includes("%3B")) {
      this.router.navigateByUrl(url.replace("%3B", ";"));
    } else if (url.includes("%3D")) {
      this.router.navigateByUrl(url.replace("%3D", "="));
    } else {
      this.router.navigate(["/login"]);
    }
  }

  // Start of sub-menu control toggle
  public mouseEnter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  public mouseLeave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  private setupIsAuthorizedSelectOrg() {
    this.isAuthorizedSelectOrg = this.userService.isMonitoringUser || this.userService.isAuthorizedSync(null, "organizations", "GET");
  }

  public changeOrgAndService(): void {
    const dialog = this.dialog.open(OrgServiceSelectionComponent, {
      data: {},
    });
    dialog.afterClosed().subscribe((result: { org?: Reference; service?: Reference; monitOrg?: Reference; monitService?: Reference }) => {
      if (result?.monitOrg) this.userInfos.changeMonitOrganization(result.monitOrg);
      if (result?.monitService) this.userInfos.changeMonitService(result.monitService);
      if (result?.org) this.userInfos.changeOrganization(result.org);
      if (result?.service) this.userInfos.changeService(result.service);
      this.drawer.close();
    });
  }
}
